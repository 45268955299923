import $ from 'jquery'

/*
  1. Our jquery used to be loaded through sprockets through as a normal script in the HEAD tag (no defer/module/async)
  2. That means it'd block further DOM parsing until it was evaluaated - document.readyState in this case would always be 'loading' as the jquery was being parsed.
  3. However, we've since stared moving things to vite, which by defaults serves everything as `type=module`
  4. module scripts don't block parsing, and are evaluated after the body is parsed but before DOMContentLoaded
  5. Jquery's behavior of when the ready event fires differs. If jquery is evaluated when document.readyState is 'loading', then it'd wait until DOMContentLoaded or 'load' event. However, if the document.readyState is 'interactive', which it would be for module scripts as they don't block parsing, ready events are fired instantly (before DOMContentLoaded).
  6. There are various things in our codebase that don't work correctly when ready is fired early. One example is turbolinks/jquery.turbolinks. It's fairly convoluted/complicated/jarring to explain - so not even going to try explaining. But if you ever feel so confident to get rid of the holdReady here, make sure you absolute know what you're doing and things work fine.
*/
$.holdReady(true)
document.addEventListener('DOMContentLoaded', () =>
  setTimeout(() => $.holdReady(false), 1)
)

$.migrateMute = 1
window.jQuery = window.$ = $
